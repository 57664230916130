File Name: CarouselEffectFade.vue
<!-- =========================================================================================
	Description: Carousel with fade effect
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="m-0" style="width: 600px">
    <!-- swiper -->
    <swiper
      :options="swiperOption"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :key="$vs.rtl"
      loop
      rewind
    >
      <swiper-slide>
        <iframe
          src="https://drive.google.com/file/d/1BYxdKTQU77uWDelXEKKddsfOd0BuSONp/preview"
          width="100%"
          height="315"
          allow="autoplay"
        ></iframe>
        <div class="p-5">
          <h2 class="mb-5">Welcome to your Drugbook</h2>
          <p class="mb-10">Explainer Text</p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/6ktnrKG8uq4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div class="p-5">
          <h2 class="mb-5">Welcome to your Drugbook</h2>
          <p class="mb-10">Explainer Text</p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/_S7WEVLbQ-Y"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div class="p-5">
          <h2 class="mb-5">Welcome to your Drugbook</h2>
          <p class="mb-10">Explainer Text</p>
        </div>
      </swiper-slide>

      <div
        class="swiper-pagination swiper-pagination-white"
        slot="pagination"
      ></div>
      <div
        class="swiper-button-prev swiper-button-white"
        slot="button-prev"
      ></div>
      <div
        class="swiper-button-next swiper-button-white"
        slot="button-next"
      ></div>
    </swiper>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    open: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      explainerPopUp: false,
    };
  },
};
</script>
<style>

</style>
