<template>
  <div
    class="banner-container gap-5 p-5 mb-5 rounded-lg border-primary3 bg-primary3"
  >
    <img src="/final.png" class="customImg" />
    <div class="space-y-2">
      <p class="font-bold lg:text-lg text-base">
        A message from the Fresh Clinics Team regarding your Digital Drugbook
      </p>
      <p class="lg:text-justify text-left lg:text-base text-sm">
        The team has identified issues with the Digital Drugbook that was
        causing duplicate drugbook entries to be created. We have now resolved
        this issue and removed the duplicate records from your Drugbook. As a
        result of this, your product balances may now be incorrect. We recommend
        completing a Stocktake via the Inventory section of the Mobile App to
        correct your balances.
        <a class="underline" :href="url" target="_blank"
          >To learn more about this issue please refer to this article.</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "IncorrectBalanceBanner",
  props: ["url"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.banner-container {
  display: flex;
  align-items: flex-start;
  border: 1px solid;
  --bg-opacity: 0.05 !important;
}
.customImg {
  width: 28px;
  height: 28px;
  /* Convert Image color to black */
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(0%)
    contrast(100%);
}
</style>
