<template>
  <div>
    <vs-button
      :color="buttonCollor"
      :type="buttonType"
      :class="buttonClass"
      @click="poUpActive = true"
    >
      {{ buttonText }}
    </vs-button>

    <div
      v-if="poUpActive"
      class="h-screen w-screen bg-opacity-25 bg-black fixed top-0 left-0"
    >
      <div
        id="popUpMask"
        @click="onCancel"
        class="h-full w-full flex items-center justify-center"
      >
        <div class="w-1/4 vs-popup" v-on:click.stop>
          <div id="popUpHeader" class="vs-popup--header">
            <di class="vs-popup--title">
              <h3>{{ popUpTitle }}</h3>
            </di>
          </div>
          <div class="vs-popup--content p-5">
            <div class="py-5">
              <p>
                This will create a new entry in your drugbook, and the original
                will be struck out but still visible for reference.
              </p>
            </div>
            <div id="popUp footer" class="text-right w-full">
              <vs-button
                @click="onCancel"
                color="primary"
                type="border"
                class="mr-2"
              >
                {{ cancelText }}
              </vs-button>
              <vs-button color="primary" type="filled" @click="onOk">
                {{ confirmText }}
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: "Delete",
    },
    buttonType: {
      type: String,
      default: "flat",
    },
    buttonCollor: {
      type: String,
      default: "danger",
    },
    buttonClass: {
      type: String,
      default: "w-full radius",
    },
    popUpTitle: {
      type: String,
      default: "Delete Record?",
    },
    popUpMessage: {
      type: String,
      default: "Are you sure you want to delete record?",
    },
    confirmText: {
      type: String,
      default: "Confirm",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
  },
  data: () => ({
    poUpActive: false,
  }),
  methods: {
    onOk() {
      this.$emit("onConfirm");
    },
    onCancel() {
      this.poUpActive = false;
      this.$emit("onCancel");
    },
  },
};
</script>

<style></style>
