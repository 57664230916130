<template>
  <div class="mr-2 progress-container">
    <strong>Drugbook Completion Rate</strong>
    <div>{{drugentries}}/{{appointments}} Appointments this Month</div>
    <div class="flex items-center">
      <span>0</span>
      <div class="progress">
        <div class="progress-bar" :style="{ width: percentage + '%' }">
          <span>{{percentage}}%</span>
        </div>
      </div>
      <span>100%</span>
    </div>

  </div >
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: 'ProgressBar',
  props: {
    month: String,
    year: String,
    clinicId: String
  },
  data() {
    return {
      percentage: 0,
      appointments: 0,
      drugentries: 0
    }
  },
  methods:{
    ...mapActions("drugbook", ["fetchCompletionPercentage"]),
    async getCompletionPercentage(month, year, clinicId){
      try {
        const res = await this.fetchCompletionPercentage({month, year, clinicId});
        if(res.data.data.percentage){
          this.percentage = res.data.data.percentage
        } else {
          this.percentage = 0
        }

        this.appointments = res.data.data.appointments
        this.drugentries = res.data.data.drugentries
      } catch (error) {
        console.error(error)
      }
    },
  },
  computed: {
    params() {
      return `${this.month}|${this.year}|${this.clinicId}`;
    },
  },
  watch: {
    params(newVal) {
      const [month, year, clinicId] = newVal.split('|');
      if(month && year && clinicId){
        this.getCompletionPercentage(month, year, clinicId)
      }
    },
  },
  mounted(){
      const [month, year, clinicId] = this.params.split('|');
      if(month && year && clinicId){
        this.getCompletionPercentage(month, year, clinicId)
      }
  }
}
</script>
<style>
  .progress-container{
    border: 1px solid #f6f6f6;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 1rem;
    min-width: 35%;
  }
  .progress {
    margin: 0 12px;
    width: 100%;
    background: #93929221;
    height: 10px;
  }
  .progress-bar {
    background: rgba(var(--vs-primary), 1);
    height: 10px;
  }
  .progress-bar span{
    visibility:hidden
  }

</style>
